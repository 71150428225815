import React from 'react'
import PropTypes from 'prop-types'
import RichText from '@components/RichTextAndTypography'

const ContentItemApolloCPAContainer = ({ theme, body, ...rest }) => {
  return <RichText theme={theme} richText={body} {...rest} />
}

ContentItemApolloCPAContainer.propTypes = {
  theme: PropTypes.string,
}

export default ContentItemApolloCPAContainer
