import React from 'react'
import PropTypes from 'prop-types'
import { ContentGroupGatsbyCDAContainer } from '@containers/ContentGroup'
import { ContentItemGatsbyCDAContainer } from '@containers/ContentItem'
import { ContentGroupImageVideoGalleryGatsbyCDAContainer } from '@containers/ContentGroupImageVideoGallery'
import { ModuleTwoColumnGatsbyCDAContainer } from '@containers/ModuleTwoColumnContainer'
import Module from '@components/Module'
import { hexToRgb, handleRemoveContentfulFromContentTypeString } from '@utils'

const parseBackground = background => {
  const source = background?.imageVideoOrSolidColor?.[0]

  let backgroundImage = {}

  const backgroundPortrait =
    source?.image?.sourcePortrait || source?.sourcePortrait
  backgroundImage.portrait = {
    src: backgroundPortrait?.fluid,
    alt: backgroundPortrait?.description || backgroundPortrait?.title,
  }

  const backgroundLandscape =
    source?.image?.sourceLandscape || source?.sourceLandscape
  backgroundImage.landscape = {
    src: backgroundLandscape?.fluid,
    alt: backgroundLandscape?.description || backgroundLandscape?.title,
  }

  const backgroundGradientColors = background?.gradient?.colors?.map(
    ({ hex, opacity }) => hexToRgb(hex, opacity)
  )

  const backgroundGradientDirection = background?.gradient?.direction
  const backgroundGradient =
    backgroundGradientDirection &&
    backgroundGradientColors &&
    `linear-gradient(${backgroundGradientDirection}, ${backgroundGradientColors?.join(
      ','
    )})`

  const backgroundVideo = {
    sourceLandscape: source?.cloudinaryVideoSource?.[0]?.original_secure_url,
    sourcePortrait:
      source?.cloudinaryVideoSourcePortrait?.[0]?.original_secure_url,
    id: source?.id,
    posterLandscape: {
      src: source?.posterLandscape?.fluid || source?.posterLandscape?.file?.url,
      alt:
        source?.posterLandscape?.description || source?.posterLandscape?.title,
    },
    posterPortrait: {
      src: source?.posterPortrait?.fluid || source?.posterPortrait?.file?.url,
      alt: source?.posterPortrait?.description || source?.posterPortrait?.title,
    },
    videoBehavior: source?.videoBehavior,
  }

  let cT = source?.contentType || source?.type

  return {
    backgroundColor: hexToRgb(source?.hex, source?.opacity),
    backgroundImage,
    backgroundGradient,
    backgroundVideo,
    backgroundType: handleRemoveContentfulFromContentTypeString(cT),
  }
}

const renderModuleBasedOnType = ({
  id,
  background,
  type,
  contentType,
  theme,
  bodyPadding,
  order,
  lastModule,
  cards,
  items,
  sources,
  addExternalPadding,
  ...fields
}) => {
  if (!bodyPadding) {
    bodyPadding = {
      desktopTop: 0,
      desktopBottom: 0,
      mobileTop: 0,
      mobileBottom: 0,
    }
  }

  const parsedBackground = parseBackground(background)
  const renderBody = ({ contentType, id, t }) => {
    let theme = t?.toLowerCase()
    switch (contentType) {
      case 'ContentfulModuleCardsListManual':
        return (
          <ContentGroupGatsbyCDAContainer
            key={`group-${id}`}
            theme="light"
            items={cards}
            {...fields}
          />
        )
      case 'ContentfulModuleCardsListAutomatic':
        return (
          <ContentGroupGatsbyCDAContainer
            key={`group-${id}`}
            theme="light"
            items={items}
            {...fields}
          />
        )
      case 'ContentfulModuleBackgroundBanner':
        return (
          <ContentItemGatsbyCDAContainer
            key={`item-${id}`}
            theme={theme}
            bodyPadding={bodyPadding}
            items={items}
            {...fields}
          />
        )

      case 'ContentfulModuleGallery':
        return (
          <ContentGroupImageVideoGalleryGatsbyCDAContainer
            key={`carousel-${id}`}
            theme={theme}
            items={items}
            {...fields}
          />
        )
      case 'ContentfulModuleSoundCloud':
        return (
          <ContentGroupGatsbyCDAContainer
            key={`group-${id}`}
            theme="light"
            items={sources}
            layout="Soundcloud"
            {...fields}
          />
        )
      case 'ContentfulModuleTwoColumnShowcase':
        return <ModuleTwoColumnGatsbyCDAContainer {...fields} />
      default:
        return null
    }
  }

  return (
    <Module
      key={id}
      theme={theme?.toLowerCase()}
      bodyPadding={bodyPadding}
      order={order}
      lastModule={lastModule}
      noMinPaddingTop={contentType === 'ContentfulModuleGallery'}
      addExternalPadding={addExternalPadding}
      {...parsedBackground}
    >
      {renderBody({ contentType: type || contentType, id, t: theme })}
    </Module>
  )
}

const Modules = ({ modules = [] }) => {
  if (!modules) return null

  return modules.map(({ id, ...rest }, index) =>
    renderModuleBasedOnType({
      id,
      order: index + 1,
      lastModule: index + 1 === modules.length,
      ...rest,
    })
  )
}

Modules.propTypes = {
  Modules: PropTypes.array,
}
export default Modules
