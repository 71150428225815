import React from 'react'
import PropTypes from 'prop-types'
import Module from '@components/Module'
import { ContentGroupApolloCPAContainer } from '@containers/ContentGroup'
import { ContentItemApolloCPAContainer } from '@containers/ContentItem'
import { FormApolloCPAContainer } from '@containers/Form'
import { hexToRgb } from '@utils'
import { ContentGroupImageVideoGalleryApolloCPAContainer } from '@containers/ContentGroupImageVideoGallery'
import { ModuleTwoColumnApolloCPAContainer } from '@containers/ModuleTwoColumnContainer'

const parseBackground = (background, type) => {
  if (
    type === 'cardBlock' ||
    type ===
      'contentGroupCarousel' /* named Content Group – Image/Video Gallery on contentful */
  ) {
    return {
      backgroundColor: 'black',
      backgroundType: 'color',
    }
  }
  const source = background?.imageVideoOrSolidColor

  let backgroundImage = {}

  const backgroundPortrait =
    source?.image?.sourcePortrait || source?.sourcePortrait
  backgroundImage.portrait = {
    src: backgroundPortrait?.file?.url,
    alt: backgroundPortrait?.description || backgroundPortrait?.title,
  }

  const backgroundLandscape =
    source?.image?.sourceLandscape || source?.sourceLandscape
  backgroundImage.landscape = {
    src: backgroundLandscape?.file?.url,
    alt: backgroundLandscape?.description || backgroundLandscape?.title,
  }

  const backgroundGradientColors = background?.gradient?.colors?.map(
    ({ hex, opacity }) => hexToRgb(hex, opacity)
  )

  const backgroundGradientDirection = 'to bottom'
  const backgroundGradient =
    backgroundGradientDirection &&
    backgroundGradientColors &&
    `linear-gradient(${backgroundGradientDirection}, ${backgroundGradientColors?.join(
      ','
    )})`

  const backgroundVideo = {
    sourceLandscape: source?.cloudinaryVideoSource?.[0]?.original_secure_url,
    sourcePortrait:
      source?.cloudinaryVideoSourcePortrait?.[0]?.original_secure_url,
    id: source?.id,
    posterLandscape: {
      src: source?.posterLandscape?.file?.url,
      alt:
        source?.posterLandscape?.description || source?.posterLandscape?.title,
    },
    posterPortrait: {
      src: source?.posterPortrait?.file?.url,
      alt: source?.posterPortrait?.description || source?.posterPortrait?.title,
    },
    videoBehavior: source?.videoBehavior,
  }

  return {
    backgroundColor: hexToRgb(source?.hex, source?.opacity),
    backgroundImage,
    backgroundGradient,
    backgroundVideo,
    backgroundType: source?.type,
  }
}

const renderModuleBasedOnType = ({
  id,
  background,
  type,
  theme,
  bodyPadding,
  items,
  order,
  cards,
  lastModule,
  layout,
  feedSource,
  sources,
  ...fields
}) => {
  const parsedBackground = parseBackground(background, type)
  const renderBody = ({ type, id }) => {
    switch (type) {
      case 'moduleCardsListManual':
        return (
          <ContentGroupApolloCPAContainer
            key={`group-${id}`}
            theme="light"
            items={cards}
            layout={layout}
            type="manualCardGroup"
            {...fields}
          />
        )
      case 'moduleCardsListAutomatic':
        return (
          <ContentGroupApolloCPAContainer
            key={`group-${id}`}
            theme="light"
            items={{
              source: {
                id: feedSource?.id,
                type: feedSource?.type,
                title: feedSource?.title,
              },
            }}
            layout={layout}
            type="automaticCardGroup"
            {...fields}
          />
        )
      case 'moduleBackgroundBanner':
        return (
          <ContentItemApolloCPAContainer
            key={`item-${id}`}
            theme={theme}
            bodyPadding={bodyPadding}
            layout={layout}
            {...fields}
          />
        )
      case 'moduleGallery': // named Content Group – Image/Video Gallery
        return (
          <ContentGroupImageVideoGalleryApolloCPAContainer
            key={`contentGroupCarousel-${id}`}
            items={items}
            theme={theme}
            layout={layout}
            {...fields}
          />
        )
      case 'moduleSoundCloud':
        return (
          <ContentGroupApolloCPAContainer
            key={`group-${id}`}
            theme="light"
            items={{ items: sources }}
            layout="Soundcloud"
            type="manualCardGroup"
            {...fields}
          />
        )
      case 'moduleTwoColumns':
        return <ModuleTwoColumnApolloCPAContainer {...fields} />
      default:
        return null
    }
  }

  return (
    <Module
      key={id}
      theme={theme ? theme?.toLowerCase() : 'light'}
      bodyPadding={bodyPadding}
      order={order}
      lastModule={lastModule}
      noMinPaddingTop={type === 'moduleGallery'}
      {...parsedBackground}
    >
      {renderBody({ type, id })}
    </Module>
  )
}

const Modules = ({ modules = [] }) => {
  if (!modules || !Array.isArray(modules)) {
    return null
  } else {
    return modules?.map(({ type, ...rest }, index) =>
      renderModuleBasedOnType({
        type,
        order: index + 1,
        lastModule: index + 1 === modules.length,
        ...rest,
      })
    )
  }
}

Modules.propTypes = {
  Modules: PropTypes.array,
}
export default Modules
