import React from 'react'
import PropTypes from 'prop-types'
import Box from '@components/Box'
import ModuleBackgroundWrapper from './components/ModuleBackgroundWrapper'
import { useMobile } from '@hooks/'

const ModuleWrapper = ({
  children,
  color = 'black',
  height = 20,
  hex = 'white',
  theme = 'light',
  bodyPadding,
  order,
  lastModule,
  noMinPaddingTop = false,
  addExternalPadding = false,
  ...rest
}) => {
  const [isMobile] = useMobile()
  const paddingTop = bodyPadding
    ? isMobile
      ? bodyPadding?.mobileTop
      : bodyPadding?.desktopTop
    : '0'
  const paddingBottom = bodyPadding
    ? isMobile
      ? bodyPadding?.mobileBottom
      : bodyPadding?.desktopBottom
    : '0'

  const minPaddingTop = order === 1 ? 150 : order === 2 ? 50 : 20
  const minPaddingBottom = order === 1 ? 150 : lastModule ? 0 : 0

  return (
    <ModuleBackgroundWrapper theme={theme} {...rest}>
      <Box
        className="module"
        pt={
          paddingTop === 0 || noMinPaddingTop
            ? '0'
            : `calc(${paddingTop}% + ${minPaddingTop}px)`
        }
        pb={
          paddingBottom === 0
            ? '0'
            : `calc(${paddingBottom}% + ${minPaddingBottom}px)`
        }
        display="flex"
        flexDirection="column"
        alignItems="center"
        color={`${theme}.text`}
        mx="auto"
        my={addExternalPadding ? 5 : 0}
      >
        {children}
      </Box>
    </ModuleBackgroundWrapper>
  )
}
const Module = ({ children, ...rest }) => {
  return <ModuleWrapper {...rest}>{children}</ModuleWrapper>
}

Module.propTypes = {
  bodyAlignment: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  color: PropTypes.string,
  height: PropTypes.number,
  hex: PropTypes.string,
}

export default Module
