import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import Video from '@components/Video'
import { useMobile } from '@hooks/'
import Box from '@components/Box'

const BackgroundGradientColor = ({
  theme = 'light',
  color,
  gradient,
  children,
}) => {
  const bgColor = color || `${theme}.bg`
  return (
    <Box position="relative" fullWidth>
      <BackgroundImage
        Tag="section"
        fluid={[gradient || bgColor]}
        backgroundColor={bgColor}
      >
        {children}
      </BackgroundImage>
    </Box>
  )
}

const BackgroundGradientImage = ({ image, gradient, children }) => {
  const [isMobile] = useMobile()
  const backgroundSize = 'cover'
  const imageSrcType = isMobile ? image?.portrait : image?.landscape
  const imageAlt = imageSrcType?.alt
  let imageSrc = imageSrcType?.src

  if (typeof imageSrc === 'string') {
    imageSrc = imageSrcType
  }
  return (
    <Box position="relative" fullWidth>
      <BackgroundImage
        Tag="section"
        fluid={
          gradient && imageSrc
            ? [gradient, imageSrc]
            : gradient
            ? [gradient]
            : imageSrc
        }
        style={{
          backgroundSize,
          backgroundPosition: 'center top',
        }}
        alt={imageAlt}
      >
        {children}
      </BackgroundImage>
    </Box>
  )
}

const BackgroundWrapper = ({
  backgroundColor,
  backgroundImage,
  backgroundGradient,
  backgroundVideo,
  children,
  backgroundType,
  bodyPadding,
  theme,
}) => {
  switch (backgroundType) {
    case 'color':
    case 'componentColor':
      return (
        <BackgroundGradientColor
          theme={theme}
          color={backgroundColor}
          gradient={backgroundGradient}
        >
          {children}
        </BackgroundGradientColor>
      )

    case 'uiImage':
    case 'image':
      return (
        <BackgroundGradientImage
          image={backgroundImage}
          gradient={backgroundGradient}
        >
          {children}
        </BackgroundGradientImage>
      )
    case 'uiVideo':
    case 'video':
      return (
        <Box position="relative" fullWidth>
          <Video
            bgVideo
            gradient={backgroundGradient}
            {...backgroundVideo}
            bodyPadding={bodyPadding}
          >
            {children}
          </Video>
        </Box>
      )
    default:
      return <div>{children}</div>
  }
}

BackgroundWrapper.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
  backgroundGradient: PropTypes.string,
}

export default BackgroundWrapper
